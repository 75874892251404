 body {
   background-color: rgba(0, 0, 0, 0.819);
 }

 .app {
    margin: 0 auto;
    max-width: 1000px;
    background-color: #FFF;
 }

 