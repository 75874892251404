 .body {
  font-family: 'Times New Roman',  sans-serif;
 }
 .app-info {
    padding: 25px;
    background-color: #000000;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, .15);
    color: #FFF;

 }
 .app-info {
  text-align: center;
}

.app-info-title {
  font-family: 'Times New Roman',  sans-serif;
  font-size: 55px;
  font-weight: bold;
  animation: colorChange 7s infinite;
}



/* Мобильная адаптация для ширины 820px */
@media screen and (max-width: 820px) {
  .movie-list h2 {
    font-size: 90px;
  }

.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
  .btn {
    width: 100%;
  }
.btn-group {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}


}

/* Мобильная адаптация для ширины 720px */
@media screen and (max-width: 720px) {
.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
  .btn {
    width: 100%;
  }
.btn-group {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
}

/* Мобильная адаптация для ширины 390px */
@media screen and (max-width: 390px) {
.btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
  .btn {
    width: 100%;
  }
.btn-group {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
  .app-info-title {
    font-size: 40px;
    margin: 0 auto;
  }
}

/* Мобильная адаптация для ширины 375px */
@media screen and (max-width: 375px) {
  .btn-container {
    margin-bottom: 0;
    text-align: center;
  }
    .btn-group {
    justify-content: center;
  }
  .app-info-title {
    font-size: 30px;
    margin: 0 auto;
  }
}