html {
  margin: 0;
  background-color: black;
   
}
#root  {
  margin: 0 auto;
  background-color: black;
  
}

.movie-list {
  text-align: center;
  margin: 0 auto;
}

.movie-list-title {
  margin: 25px 0;
}
.modal-header {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  column-gap: 25px;
  
}
.list-cast-item {
  max-height: 300px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
}

.list-cast-item li {
  max-width: 300px;
  word-wrap: break-word;
}





.movie-description {
  text-align: center;
  margin: 0 auto;
  display: center;
  justify-content: center;
}

.movie-list h2 {
  font-family: 'Times New Roman',  sans-serif;
  font-size: 115px;
  font-weight: bold;
  padding-top: 140px;
  padding-bottom: 200px;
  color: #FFFF;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnPSMKWGqVf_toqEcoKTDl9NS4zDEFPnhfBRRc5S1pVTGUpdGfKdSK4IoPYWFzUk-lZM0&usqp=CAU);
  background-size: cover;
  position: relative;
  margin: 0;
  animation: colorChange 7s infinite;
}
.movie-image {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

@keyframes colorChange {
  0% {
    color: rgb(102, 32, 32);
  }
  25% {
    color: #FF0000;
  }
  50% {
    color: orange;
  }

  100% {
    color: rgb(171, 134, 96);
  }
}

.movie-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  grid-gap: 20px;
  list-style: none;
}

.movie-item {
  position: relative;
  transition: opacity 0.3s, transform 0.3s;
  cursor: pointer;
}

.movie-item:hover {
  opacity: 0.8;
  transform: translateY(-2px);
}

.movie-item:active {
  opacity: 0.6;
  transform: translateY(2px);
}

.movie-image img {
  width: 200px;
  height: auto;
}

.movie-details {
  margin-top: 10px;
}

.movie-title {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.movie-rating {
  margin: 5px 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.movie-release-date {
  margin: 0;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.movie-description {
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.movie-description ul {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.movie-description li {
  list-style-type: none;
}

.pagination {
  justify-content: center;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 25px;
  padding-top: 25px;
  margin: 0 auto;
}


@media screen and (max-width: 820px) {
  .movie-list h2 {
    font-size: 40px;
  }

  .movie-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .movie-item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .movie-image img {
    width: 100%;
  }

  .movie-list h2 {
    font-size: 50px;
  }

  .movie-title {
    font-size: 12px;
  }

  .movie-rating {
    font-size: 10px;
  }

  .movie-release-date {
    font-size: 10px;
  }

  .movie-description {
    font-size: 10px;
  }

  .btn-group button {
    font-size: 10px;
    padding: 5px 10px;
  }
}




@media screen and (max-width: 740px) {
  .movie-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .movie-item {
    margin-left: 10px;
    margin-right: 10px;
  }

  .movie-image img {
    width: 100%;

  }

  .movie-list h2 {
    font-size: 40px;
    padding-top: 60px;
    background-position: center;
  }

  .movie-title {
    font-size: 12px;
  }

  .movie-rating {
    font-size: 10px;
  }

  .movie-release-date {
    font-size: 10px;
  }

  .movie-description {
    font-size: 10px;
  }

  .btn-group button {
    font-size: 10px;
    padding: 5px 10px;
  }

}



@media screen and (max-width: 390px) {
  .movie-grid {
    grid-template-columns: 1fr;
  }

  .movie-item {
    margin-left: auto;
    margin-right: auto;
  }

  .movie-image img {
    width: 100%;
  }

  .movie-list h2 {
    font-size: 40px;
    padding-top: 55px;
    padding-bottom: 100px;
  }

  .movie-title {
    font-size: 10px;
  }

  .movie-rating {
    font-size: 8px;
  }

  .movie-release-date {
    font-size: 8px;
  }

  .movie-description {
    font-size: 8px;
  }

  .btn-group button {
    font-size: 8px;
    padding: 5px 10px;
  }

  .load-more-button {
    font-size: 10px;
    padding: 8px 16px;
  }
}

