
.search-form {
    margin-top: 25px;
}
.search-panel {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    padding: 25px;
    background-color: #000000;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, .15);
    color: #fff;
    margin-bottom: 25px;
}

.btn {
    margin-top: 20px;
    margin-bottom: 20px;
}

